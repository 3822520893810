import { Controller } from "@hotwired/stimulus"
import { DirectUpload } from "@rails/activestorage"

//https://guides.rubyonrails.org/active_storage_overview.html#custom-drag-and-drop-solutions
export default class extends Controller {
    static targets = ["input", "cachedFile"];
    static values = {url: String};

    async previewFiles() {
      // Process each file sequentially
      for (const file of this.inputTarget.files) {
        await this.#uploadFile(file);
      }
    }

    #uploadFile(file) {
      return new Promise((resolve, reject) => {
        const upload = new DirectUpload(file, this.urlValue)

        upload.create((error, blob) => {
          if (error) {
            console.log(error);
            reject(error);
          } else {
            const hiddenField = document.createElement("input");
            hiddenField.setAttribute("id", `attachment_${blob.filename}`);
            hiddenField.setAttribute("type", "hidden");
            hiddenField.setAttribute("value", blob.signed_id);
            hiddenField.dataset.directUploadTarget = 'cachedFile';
            hiddenField.name = this.inputTarget.name;
            this.element.appendChild(hiddenField);
            resolve(blob);
          }
        });
      });
    }
}
